import { Col, Collapse, Row, Spin } from 'antd';
import React from 'react';
import { colors } from '../../lib/colors';
import TimeCardActions from './TimeCardActions';
import TimeCardBillingCodes from './TimeCardBillingCodes';
import TimeCardNotes from './TimeCardNotes';
import BillableSwitch from './TimeCardServices';

import {
  BillingCode,
  DerivedTimeEntry,
  TimeEntry,
  Timer,
  ValidationResult,
} from '../../lib/types';
import { validateTimeEntry } from '../../lib/util';
import TimerDisplay from './Timer';
import TimeToLog from './TimeToLog';

const { Panel } = Collapse;

const TimeCard = (props: {
  clients: Record<string, BillingCode[]>;
  isDirty: boolean;
  isDisabled: boolean;
  isProcessing: boolean;
  isRunning: boolean;
  isSaved: boolean;
  mostRecentBillingCodes: BillingCode[];
  onChange: (field: keyof TimeEntry, value: any) => void;
  onCopy: () => void;
  onDelete: () => void;
  onPause: () => void;
  onPlay: () => void;
  onRevert: () => void;
  onSave: () => void;
  timeEntry: DerivedTimeEntry;
  timer: Timer;
  timerStart: number;
}) => {
  const { timeEntry } = props;

  // calculate time entry completeness - timeEntry is not saveable unless timer is greater than or equal to 60 seconds, so check to see if timeEntry.timer is greater than or equal to 60 seconds, if not, incompleteFields should evaluate to false;
  const incompleteFields: ValidationResult[] = React.useMemo(() => {
    return validateTimeEntry(timeEntry, props.timer);
  }, [timeEntry, props.timer]);

  const activeStartTime = props.isRunning ? props.timerStart : null;

  const highlightColor = React.useMemo(() => {
    if (props.isRunning) {
      return colors.blue.borderColor;
    } else if (incompleteFields.length) {
      return colors.red.borderColor;
    } else if (props.isDirty) {
      return colors.gold.borderColor;
    } else {
      return null;
    }
  }, [props.isRunning, props.isDirty, incompleteFields]);

  return (
    <Collapse
      // is complete and not dirty = no style, is complete and dirty = gold, is not complete = red
      style={{ borderColor: highlightColor }}
      collapsible={'header'}
      defaultActiveKey={['1']}
    >
      <Spinner isVisible={props.isProcessing} />
      <Panel
        // is complete and not dirty = no style, is complete and dirty = gold, is not complete = red
        key={'1'}
        showArrow={true}
        style={{ borderColor: highlightColor }}
        header={
          <Row>
            <Col
              span={8}
              onClick={(e) => e.stopPropagation()}
              style={{ cursor: 'default', paddingLeft: 4 }}
            >
              <TimerDisplay
                elapsedSeconds={timeEntry.duration}
                activeTimerStart={activeStartTime}
                onUpdate={(v) => props.onChange('duration', v)}
                isSaved={!props.isDirty}
              />
            </Col>
            <Col span={8} style={{ textAlign: 'center', paddingTop: 5 }}>
              {props.timeEntry.billingCode.clientName +
                ' - ' +
                props.timeEntry.billingCode.fullCode}
            </Col>
            <Col
              span={8}
              onClick={(e) => e.stopPropagation()}
              style={{ cursor: 'default' }}
            >
              <TimeCardActions
                incompleteFields={incompleteFields}
                isDirty={props.isDirty}
                isDisabled={props.isDisabled}
                isRunning={props.isRunning}
                onPause={props.onPause}
                onCopy={props.onCopy}
                onDelete={props.onDelete}
                onlyExistsLocally={!props.isSaved}
                onPlay={props.onPlay}
                onRevert={props.onRevert}
                onSave={props.onSave}
              />
            </Col>
          </Row>
        }
      >
        <Row gutter={[16, 16]} style={{ textAlign: 'left', clear: 'both' }}>
          <Col span={12}>
            <div>
              {timeEntry.billingCode?.clientName ? (
                <i>{timeEntry.billingCode?.clientName}</i>
              ) : (
                <i>No Client Selected</i>
              )}
            </div>
            <TimeCardBillingCodes
              clients={props.clients}
              isDisabled={props.isDisabled}
              mostRecentBillingCodes={props.mostRecentBillingCodes}
              onChange={props.onChange}
              timeEntry={timeEntry}
            />
            {timeEntry.billingCode?.clientName &&
            !process.env.REACT_APP_NULL_TASKTYPE_CLIENTS.includes(
              timeEntry.billingCode?.clientId
            ) ? (
              <BillableSwitch onChange={props.onChange} timeEntry={timeEntry} />
            ) : (
              <></>
            )}
          </Col>
          <Col span={12}>
            <TimeCardNotes
              isDisabled={props.isDisabled}
              note={timeEntry.note}
              onChange={(v: string) => {
                props.onChange('note', v);
              }}
            />
          </Col>
        </Row>
        <Row
          justify={'center'}
          gutter={16}
          align={'middle'}
          style={{ marginTop: 10 }}
        >
          <Col span={12} style={{ textAlign: 'right' }}>
            <TimeToLog
              elapsedSeconds={timeEntry.duration}
              activeTimerStart={activeStartTime}
            />
          </Col>
          <Col span={8} style={{ textAlign: 'left' }}>
            <span style={{ marginRight: 10 }}>Hours logged in Salesforce:</span>
            <span>{timeEntry.billHours.toFixed(2)}</span>
          </Col>
          <Col
            span={4}
            style={{
              textAlign: 'right',
            }}
          >
            <div>
              {!timeEntry.isNew ? (
                <a
                  href={`${process.env.REACT_APP_SF_URL}/lightning/r/Freshbooks_Time_Entry__c/${timeEntry.id}/view`}
                >
                  Open in Salesforce
                </a>
              ) : (
                <i>unsaved</i>
              )}
            </div>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

const Spinner = (props: { isVisible: boolean }) => {
  return (
    <Spin
      size="large"
      style={{
        position: 'absolute',
        zIndex: 1000,
        backgroundColor: '#FFF',
        opacity: '80%',
        paddingTop: 90,
        width: 'calc(100% - 26px)',
        height: '255px',
        ...(props.isVisible ? {} : { display: 'none' }),
      }}
    />
  );
};

export default TimeCard;
