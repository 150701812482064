import { ClockCircleOutlined } from '@ant-design/icons';
import { InputNumber, Popconfirm, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useElapsedTimer } from '../../hooks/useElapsedTimer';
import { colors } from '../../lib/colors';
import {
  elapsedTimeToParts,
  elapsedTimeToString,
  getSecondsSince,
} from '../../lib/util';

type TimeComponents = {
  hours: number;
  minutes: number;
  seconds: number;
};

interface Props {
  elapsedSeconds: number;
  activeTimerStart: number;
  onUpdate: (newElapsedSeconds: number) => void;
  isSaved: boolean;
}

const Timer: React.FC<Props> = ({
  elapsedSeconds,
  activeTimerStart,
  onUpdate,
  isSaved,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const isActive = activeTimerStart !== null;

  const { elapsedTime } = useElapsedTimer({
    startTime: activeTimerStart,
    updateInterval: 1000,
  });

  const displaySeconds = elapsedSeconds + elapsedTime;

  const saveEditing = ({ hours, minutes, seconds }: TimeComponents) => {
    const activeAdjustedSeconds = isActive
      ? getSecondsSince(activeTimerStart)
      : 0;
    const newElapsedSeconds =
      hours * 3600 + minutes * 60 - activeAdjustedSeconds;

    setIsEditing(false);
    onUpdate(newElapsedSeconds);
  };

  const [hours, minutes] = elapsedTimeToParts(displaySeconds);
  const tip = isActive
    ? `Timer has been running for ${Math.round(
        getSecondsSince(activeTimerStart) / 60
      )} minutes. Click to Edit`
    : 'Click to edit';

  return (
    <EditTimeModal
      key={`${isEditing}`}
      open={isEditing}
      hours={hours}
      minutes={minutes}
      onSave={saveEditing}
      onCancel={() => setIsEditing(false)}
    >
      <Tooltip placement="topRight" title={isEditing ? null : tip}>
        <Tag
          onClick={() => setIsEditing((v) => !v)}
          style={{
            cursor: 'pointer',
            float: 'left',
            ...(isActive ? colors.blue : isSaved ? colors.green : colors.gold),
          }}
        >
          <span style={{ fontSize: '20px', lineHeight: '28px' }}>
            {elapsedTimeToString(displaySeconds)}
          </span>
        </Tag>
      </Tooltip>
    </EditTimeModal>
  );
};

export default Timer;

interface EditTimeModalProps {
  open: boolean;
  hours: number;
  minutes: number;
  onSave: (newTime: { hours: number; minutes: number }) => void;
  onCancel: () => void;
  children?: React.ReactNode;
}

const EditTimeModal: React.FC<EditTimeModalProps> = ({
  open,
  hours,
  minutes,
  onSave,
  onCancel,
  children,
}) => {
  const [editedTime, setEditedTime] = useState({
    hours,
    minutes,
  });

  const handleInputChange =
    (key: keyof typeof editedTime) => (value: number) => {
      setEditedTime((v) => ({ ...v, [key]: value }));
    };

  if (!open) {
    return <>{children}</>;
  }

  return (
    <Popconfirm
      open={open}
      placement="topRight"
      icon={<ClockCircleOutlined />}
      title="Edit Time"
      description={
        <EditTimeInputs
          hours={editedTime.hours}
          minutes={editedTime.minutes}
          onHoursChange={handleInputChange('hours')}
          onMinutesChange={handleInputChange('minutes')}
        />
      }
      onConfirm={() => onSave(editedTime)}
      onCancel={() => onCancel()}
      okText="Apply"
      cancelText="Cancel"
    >
      {children}
    </Popconfirm>
  );
};

interface EditTimeInputsProps {
  hours: number;
  minutes: number;
  onHoursChange: (value: number) => void;
  onMinutesChange: (value: number) => void;
}
const EditTimeInputs: React.FC<EditTimeInputsProps> = ({
  hours,
  minutes,
  onHoursChange,
  onMinutesChange,
}) => {
  return (
    <div
      style={{
        display: 'inline-block',
        padding: 7,
        border: '1px #EEE solid',
      }}
    >
      <InputNumber
        max={24}
        min={0}
        style={{ width: 100, marginRight: 3 }}
        addonAfter="hr"
        value={hours}
        onChange={onHoursChange}
      />

      <InputNumber
        max={59}
        min={0}
        autoFocus
        style={{ width: 100, marginRight: 3 }}
        addonAfter="m"
        value={minutes}
        onChange={onMinutesChange}
      />
    </div>
  );
};
