import React from 'react';
import { Button } from 'antd';

const RefreshButton = (props: {
  isDisabled: boolean;
  onRefresh: () => void;
}) => {
  return (
    <Button
      type="primary"
      size="small"
      disabled={props.isDisabled}
      style={{ marginRight: 10 }}
      onClick={props.onRefresh}
    >
      Refresh
    </Button>
  );
};

export default RefreshButton;
