import { padStart } from 'lodash';
import { CalendarDate, TimeEntry, Timer, ValidationResult } from './types';

export const getSecondsSince = (currentTimerStart: number) =>
  Math.round((new Date().getTime() - currentTimerStart) / 1000);

// Round up to next quarter hour if at least 1 minute has elapsed
export const roundToQuarterHours = (seconds: number) => {
  const rounded =
    seconds % 900 >= 60
      ? Math.floor(seconds / 900) + 1 // More than 1 minute logged, we charge for 15 minutes
      : Math.floor(seconds / 900); // Less than 1 minute logged, we dont change
  return rounded * 0.25;
};

export const elapsedTimeToString = (elapsedSeconds: number): string => {
  const [hours, minutes, seconds] = elapsedTimeToParts(elapsedSeconds).map(
    (p) => padStart(p.toString(), 2, '0')
  );
  return `${hours}:${minutes}:${seconds}`;
};

export const elapsedTimeToParts = (
  elapsedSeconds: number
): [hours: number, minutes: number, seconds: number] => [
  Math.floor(elapsedSeconds / 3600),
  Math.floor((elapsedSeconds % 3600) / 60),
  elapsedSeconds % 60,
];

export const validateTimeEntry = (
  timeEntry: Partial<TimeEntry>,
  timer: Timer
): ValidationResult[] => {
  const validationErrors: ValidationResult[] = [];
  if (!timeEntry.billingCode?.id.length) {
    validationErrors.push({
      field: 'Billing Code',
      message: 'Please select a billing code',
    });
  }

  if (!timeEntry.note || timeEntry.note.length < 1) {
    validationErrors.push({ field: 'Note', message: 'Please add a note' });
  }

  if (timeEntry.id === timer.runningTimerId) {
    if (
      !timeEntry.duration ||
      timeEntry.duration + getSecondsSince(timer.currentTimerStart) < 60
    ) {
      validationErrors.push({
        field: 'Duration',
        message: 'Please add a duration',
      });
    }
  } else if (timeEntry.id !== timer.runningTimerId) {
    if (!timeEntry.duration || timeEntry.duration < 60) {
      validationErrors.push({
        field: 'Duration',
        message: 'Please add a duration',
      });
    }
  }

  return validationErrors;
};
