import React, { Component, ErrorInfo, ReactNode } from 'react';
import { getPersistedState, LOCAL_STORAGE_KEY } from '../lib/localStorage';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  err: Error | null;
}

class GlobalErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, err: null };
  }

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, err: _ };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error rendering service
    console.log(error, errorInfo);
  }

  render() {
    let state;
    try {
      const { bearerToken, ...rest } = getPersistedState();
      state = { ...rest, bearerToken: '**********' };
    } catch (e) {
      console.log('unable to load local state', e);
    }

    if (this.state.hasError) {
      // You can render any custom fallback UI with styles
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#008080',
            color: 'white',
            height: '100%',
            width: '100%',
            fontFamily: 'monospace',
          }}
        >
          <h1 style={{ fontSize: '3rem' }}>Something went wrong :(</h1>
          <p style={{ marginTop: '2rem', fontSize: '1.5rem' }}>
            You may try to clear your local state by clicking{' '}
            <a
              href="/#"
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              here
            </a>
          </p>
          <div style={{ marginTop: '2rem', fontSize: '1rem' }}>
            Please share the following details with the dev team:
          </div>
          <div
            style={{
              backgroundColor: 'black',
              color: 'green',
              padding: '10px',
              margin: '2rem',
              fontFamily: 'Courier New, monospace',
            }}
          >
            {this.state.err.stack.toString()}
            <pre
              style={{
                color: 'black',
                backgroundColor: 'grey',
                padding: '1rem',
                borderRadius: '5px',
                overflowX: 'scroll',
              }}
            >
              {state
                ? JSON.stringify(state, null, 2)
                : localStorage.getItem(LOCAL_STORAGE_KEY)}
            </pre>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default GlobalErrorBoundary;
