import { useApiFetch } from '../../hooks/useQueries';
import { Id, TimeEntry } from '../types';

/**
 * Delete Time Entry - SF
 */
export async function deleteSfTimeEntry(
  id: Id,
  apiFetch: ReturnType<typeof useApiFetch>
) {
  return await apiFetch<void>(`time-entries/${id}`, {
    method: 'DELETE',
  });
}

/**
 * Create Time Entry - SF
 */
export async function createSfTimeEntry(
  timeEntry: Partial<TimeEntry>,
  apiFetch: ReturnType<typeof useApiFetch>
) {
  return await apiFetch<TimeEntry>(`time-entries`, {
    method: 'POST',
    body: JSON.stringify(timeEntry),
  });
}

/**
 * Update Time Entry - SF
 */
export async function updateSfTimeEntry(
  timeEntry: Partial<TimeEntry>,
  apiFetch: ReturnType<typeof useApiFetch>
) {
  return await apiFetch<TimeEntry>(`time-entries/${timeEntry.id}`, {
    method: 'PATCH',
    body: JSON.stringify(timeEntry),
  });
}
