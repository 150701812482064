import React from 'react';
import { Tooltip } from 'antd';
import { CalendarDate } from '../lib/types';

const TotalsDay = (props: { hours: number; currentDate: CalendarDate }) => {
  return (
    <Tooltip placement={'left'} title={'Hours logged today'}>
      {`${props.hours.toFixed(2)} :Today`}
    </Tooltip>
  );
};

export default TotalsDay;
