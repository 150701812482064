import { GoogleOAuthProvider } from '@react-oauth/google';

import ReactDOM from 'react-dom/client';
import App from './components/App';
import { AppContextProvider } from './components/AppContext';
import GlobalErrorBoundary from './components/GlobalErrorBoundary';
import './index.css';
import { getPersistedState, updatePersistedState } from './lib/localStorage';
import { update } from './lib/update';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const persistedState = getPersistedState();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// render app
root.render(
  <GlobalErrorBoundary>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
      <AppContextProvider localStorageBearerToken={persistedState.bearerToken}>
        <App
          persistedState={persistedState}
          onPersistChanges={updatePersistedState}
        />
      </AppContextProvider>
    </GoogleOAuthProvider>
  </GlobalErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

serviceWorkerRegistration.register({
  // https://steemit.com/typescript/@jfbloom22/how-to-handle-upgrades-with-service-workers-with-cra3-and-typescript
  onUpdate: update,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
