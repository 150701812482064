import React from 'react';
import { Select } from 'antd';

import { BillingCode, DerivedTimeEntry, TimeEntry } from '../../lib/types';

const TimeCardBillingCodes = (props: {
  clients: Record<string, BillingCode[]>;
  isDisabled: boolean;
  mostRecentBillingCodes: BillingCode[];
  onChange: (field: keyof TimeEntry, value: any) => void;
  timeEntry: DerivedTimeEntry;
}) => {
  const { Option, OptGroup } = Select;

  // onChange
  const onChange = (billingCode: BillingCode): void => {
    props.onChange('billingCode', billingCode);
  };

  const value = React.useMemo(() => {
    return props.timeEntry.billingCode?.fullCode &&
      props.timeEntry.billingCode?.projectName
      ? {
          value: `${props.timeEntry.billingCode?.fullCode} - ${props.timeEntry.billingCode?.projectName}`,
        }
      : {};
  }, [
    props.timeEntry.billingCode?.fullCode,
    props.timeEntry.billingCode?.projectName,
  ]);

  return (
    <Select
      showSearch
      optionFilterProp="children"
      placeholder="Select a Billing Code"
      key="selectedBillingCode"
      style={{ width: '100%', marginTop: 10 }}
      disabled={props.isDisabled}
      {...value}
      onChange={(value) => {
        onChange(JSON.parse(value));
      }}
    >
      {props.mostRecentBillingCodes ? (
        <OptGroup
          label="Most Recent Billing Codes"
          key="Most Recent Billing Codes"
        >
          {props.mostRecentBillingCodes.length ? (
            props.mostRecentBillingCodes.map((billingCode) => {
              return (
                <Option
                  disabled={!billingCode.active}
                  client_id={billingCode.clientId}
                  value={JSON.stringify(billingCode)}
                  key={`Most Recent Billing Codes - ${billingCode.id}`}
                >
                  {billingCode.fullCode}: {billingCode.projectName}
                </Option>
              );
            })
          ) : (
            <Option disabled={true} key="No Active Billing Codes">
              No Active Billing Codes
            </Option>
          )}
        </OptGroup>
      ) : (
        <></>
      )}
      {Object.entries(props.clients).map(([clientId, billingCodes]) => (
        <OptGroup label={billingCodes[0].clientName} key={clientId}>
          {billingCodes
            .sort((a, b) => a.fullCode.localeCompare(b.fullCode))
            .map((billingCode) => {
              return (
                <Option
                  disabled={!billingCode.active}
                  client_id={billingCode.clientId}
                  value={JSON.stringify(billingCode)}
                  key={billingCode.id}
                >
                  {billingCode.fullCode}: {billingCode.projectName}
                </Option>
              );
            })}
        </OptGroup>
      ))}
    </Select>
  );
};

export default TimeCardBillingCodes;
