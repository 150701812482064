import React from 'react';
import { Tooltip } from 'antd';
import moment from 'moment';
import { CalendarDate } from '../lib/types';

const TotalsMonth = (props: { hours: number; currentDate: CalendarDate }) => {
  return (
    <Tooltip placement={'right'} title={'Hours logged for the month'}>
      {`${moment(props.currentDate).format('MMM')}: ${props.hours.toFixed(2)}`}
    </Tooltip>
  );
};

export default TotalsMonth;
