import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import GoogleLogin from './GoogleLogin';
import RefreshButton from './RefreshButton';
import { AppContext } from './AppContext';
import { ExperimentOutlined, RocketOutlined } from '@ant-design/icons';
import { Employee } from '../lib/types';

const Header = (props: {
  user: Employee;
  onClearAppData: () => void;
  onRefresh: () => void;
}) => {
  // context values for bearerToken and user
  const { bearerToken } = React.useContext(AppContext);

  return (
    <Row id="header" align="middle">
      <Col flex="10px">
        <RefreshButton
          isDisabled={bearerToken === null}
          onRefresh={props.onRefresh}
        />
      </Col>
      <Col flex={4}>
        <span style={{ paddingRight: '5px' }}>Callaway Time Tracker</span>
        <Tooltip
          placement={'top'}
          title={`ENV: ${process.env.REACT_APP_ENV_TYPE}. \n Click here to check for updates.`}
        >
          {process.env.NODE_ENV === 'production' ? (
            <RocketOutlined
              onClick={() => {
                navigator.serviceWorker.ready.then((registration) => {
                  registration.update().then(() => {
                    console.log('updated...');
                  });
                });
              }}
            />
          ) : (
            <ExperimentOutlined />
          )}
        </Tooltip>
      </Col>
      {props.user !== null && (
        <Col flex="10px" style={{ paddingRight: 10 }}>
          {props.user.firstName}&nbsp;{props.user.lastName}
        </Col>
      )}
      <Col flex="10px">
        <GoogleLogin onClearAppData={props.onClearAppData} />
      </Col>
    </Row>
  );
};

export default Header;
