import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { CalendarDate } from '../lib/types';

const DateNavigator = (props: {
  currentDate: Date;
  onSetCurrentDate: (d: CalendarDate) => void;
  unsavedDates: Date[];
}) => {
  // Go backward by 1 day
  const decrement = () => {
    const shiftedDate = new Date(
      props.currentDate.getFullYear(),
      props.currentDate.getMonth(),
      props.currentDate.getDate() - 1
    );
    props.onSetCurrentDate({
      year: shiftedDate.getFullYear(),
      month: shiftedDate.getMonth(),
      date: shiftedDate.getDate(),
    });
  };

  // Go Forward by 1 day
  const increment = () => {
    const shiftedDate = new Date(
      props.currentDate.getFullYear(),
      props.currentDate.getMonth(),
      props.currentDate.getDate() + 1
    );
    props.onSetCurrentDate({
      year: shiftedDate.getFullYear(),
      month: shiftedDate.getMonth(),
      date: shiftedDate.getDate(),
    });
  };

  // Jump to date
  const jumpToDate = (jumpTo: Dayjs): void => {
    props.onSetCurrentDate({
      year: jumpTo.year(),
      month: jumpTo.month(),
      date: jumpTo.date(),
    });
  };

  // Most recent unsaved date
  const mostRecentUnsavedDate = (): Date | null => {
    return props.unsavedDates.length
      ? props.unsavedDates.sort((a, b) => (dayjs(a).isBefore(b) ? 1 : -1))[
          props.unsavedDates.length - 1
        ]
      : null;
  };

  // Indicate which days are unsaved on the date picker
  const highlightUnsavedDates = (current: Dayjs): JSX.Element => {
    const highlight = props.unsavedDates.find(
      (date) =>
        dayjs(date).isSame(current, 'day') &&
        current.isBefore(new Date(), 'day')
    );
    const style: any = {};
    if (highlight) {
      style.border = '1px solid #FFE58F';
      style.color = '#F6BD16';
      style.backgroundColor = '#FFFBE6';
    }
    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  };

  // Notification to user that they have unsaved time entrie in the past
  const notifyUnsavedDates =
    props.unsavedDates.filter(
      (date) =>
        dayjs(date).isBefore(props.currentDate, 'day') &&
        dayjs(date).isBefore(new Date(), 'day')
    ).length > 0;

  // Memorize Moment
  const currentMoment = React.useMemo(
    () => dayjs(props.currentDate),
    [props.currentDate]
  );

  return (
    <Col>
      <Button size="small" onClick={decrement} icon={<LeftOutlined />} />
      <DatePicker
        allowClear={false}
        value={currentMoment}
        format={(v) => v.format('ddd, MMM Do YYYY')}
        onChange={jumpToDate}
        style={{ marginLeft: 10, marginRight: 10 }}
        dateRender={highlightUnsavedDates}
      />
      <Button size="small" onClick={increment} icon={<RightOutlined />} />
      {notifyUnsavedDates ? (
        <div>
          <Tag
            color={'gold'}
            style={{
              marginTop: 5,
              cursor: 'pointer',
            }}
            onClick={() => jumpToDate(dayjs(mostRecentUnsavedDate()))}
          >
            Jump to farthest unsaved time entry
          </Tag>
        </div>
      ) : (
        <></>
      )}
    </Col>
  );
};

export default DateNavigator;
