import React from 'react';
import { Button, Tooltip } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

const MoreButton = (props: {
  isVisible: boolean;
  show: () => void;
  hide: () => void;
  content: React.ReactNode;
}) => {
  const contentRef = React.useRef<HTMLSpanElement>(null);

  return (
    <>
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          transitionProperty: 'width',
          transitionDuration: '300ms',
          transitionTimingFunction: 'linear',
          display: 'inline-block',
          ...(props.isVisible
            ? { width: contentRef.current?.offsetWidth }
            : { width: 0 }),
        }}
      >
        <span ref={contentRef}>{props.content}</span>
      </div>
      <Tooltip placement={'top'} title={'More'}>
        <Button
          type="primary"
          size="small"
          ghost={true}
          icon={<MenuOutlined />}
          onClick={props.isVisible ? props.hide : props.show}
          style={{
            verticalAlign: 'baseline',
            margin: 1,
            boxShadow: 'none',
            color: '#AAA',
            borderColor: '#DDD',
          }}
          key="moreBtn"
        />
      </Tooltip>
    </>
  );
};

export default MoreButton;
