export const colors = {
  green: {
    color: '#52C41A',
    borderColor: '#B7EB8F',
    backgroundColor: '#F6FFED',
  },
  gold: {
    color: '#F6BD16',
    borderColor: '#FFE58F',
    backgroundColor: '#FFFBE6',
  },
  red: {
    color: '#FF4D4E',
    borderColor: '#FFA39E',
    backgroundColor: '#FFF1F0',
  },
  blue: {
    color: '#1890FF',
    borderColor: '#91D5FF',
    backgroundColor: '#E6F7FF',
  },
  cyan: {
    color: '#1EA3A4',
    borderColor: '#87E7DE',
    backgroundColor: '#E6FFFB',
  },
  purple: {
    color: '#9B61FD',
    borderColor: '#ADC6FF',
    backgroundColor: '#F4EFFB',
  },
};
