import { useEffect, useState } from 'react';
import { getSecondsSince } from '../lib/util';
import { useInterval } from './useInterval';

export function useElapsedTimer({
  startTime = 0,
  updateInterval = 1000,
  paused = false,
}: {
  startTime: number;
  updateInterval?: number;
  paused?: boolean;
}) {
  const [elapsedTime, setElapsedTime] = useState<number>(0);

  useEffect(() => {
    setElapsedTime(!paused && startTime ? getSecondsSince(startTime) : 0);
  }, [paused, startTime]);

  useInterval(
    () => {
      setElapsedTime(getSecondsSince(startTime));
    },
    !paused && startTime ? updateInterval : null
  );
  return { elapsedTime };
}
