import { useElapsedTimer } from '../../hooks/useElapsedTimer';
import { roundToQuarterHours } from '../../lib/util';

interface TimeToLogProps {
  elapsedSeconds: number;
  activeTimerStart: number;
}

const TimeToLog: React.FC<TimeToLogProps> = ({
  elapsedSeconds,
  activeTimerStart,
}) => {
  const { elapsedTime } = useElapsedTimer({
    startTime: activeTimerStart,
    updateInterval: 3000,
  });

  return (
    <div>
      <span>Time to log in Salesforce: </span>
      <span>
        {roundToQuarterHours(elapsedSeconds + elapsedTime).toFixed(2)}
      </span>
    </div>
  );
};

export default TimeToLog;
