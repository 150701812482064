import { useState, useCallback, useEffect, useContext } from 'react';
import { AppContext } from '../components/AppContext';

// custom error
export class HttpError extends Error {
  status: number;
  body: string;
  constructor(status: number, body: string) {
    super(body);
    Object.setPrototypeOf(this, HttpError.prototype);
    this.status = status;
    this.body = body;
  }
  displayError() {
    return `Http Error: ${this.body}`;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// Hook responsible for tracking API interaction loading states and to return data to front end
export function useQuery<T = any>(
  req: { uri: string; options?: RequestInit },
  opts?: {
    immediate?: boolean;
    skip?: boolean;
    initialValue?: T;
    onSuccess?: (result: unknown) => T;
  }
) {
  const fetchFromApi = useApiFetch();
  const { uri, options } = req;
  const {
    immediate = true,
    skip = false,
    initialValue,
    onSuccess: onResult = (it: any) => it,
  } = opts ?? {};
  const [data, setData] = useState<T | null>(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<HttpError | null>(null);

  const refetch = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!fetchFromApi) {
        throw new HttpError(401, 'No token set');
      }
      const responseJson = await fetchFromApi<T>(uri, options);
      setData(onResult(responseJson));
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [fetchFromApi, req]);

  const reset = useCallback(() => {
    setData(initialValue);
    setError(null);
    setIsLoading(null);
  }, []);

  useEffect(() => {
    if (!skip && immediate && fetchFromApi) {
      refetch();
    }
  }, [fetchFromApi, JSON.stringify(req), immediate, skip]);

  return { data, setData, error, isLoading, refetch, reset };
}

// Not in use
export function useMutation<F extends (...args: any) => Promise<any>>(req: F) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Awaited<ReturnType<F>> | null>(null);
  const [error, setError] = useState<Error | null>(null);

  return {
    error,
    isLoading,
    data,
    execute: async (
      ...args: Parameters<F>
    ): Promise<Awaited<ReturnType<F>>> => {
      try {
        setIsLoading(true);
        const result = await req(...args);
        setData(result);
        return result;
      } catch (e) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    },
    reset: () => {
      setData(null);
      setIsLoading(false);
      setError(null);
    },
  };
}

// Provide a "fetch" function which is already authenticated with the API
// Global check for 401 Errors and clear the token if found
export const useApiFetch = () => {
  const { bearerToken, setBearerToken } = useContext(AppContext);

  const fetchFn = useCallback(
    async <T>(uri: string, opts?: RequestInit) => {
      const { headers = {}, ...rest } = opts ?? {};
      const resp = await fetch(`api/${uri}`, {
        ...rest,
        headers: {
          ...headers,
          ...{
            'Authorization': `Bearer ${bearerToken}`,
            'Content-Type': 'application/json',
          },
        },
      });
      try {
        return await handleFetchResponse<T>(resp);
      } catch (e) {
        if (e instanceof HttpError) {
          if (e.status === 401) {
            setBearerToken(null);
          }
        }
        throw e;
      }
    },
    [bearerToken, setBearerToken]
  );

  return bearerToken ? fetchFn : null;
};

// Helper to parse response data, or throw error
async function handleFetchResponse<T>(resp: Response) {
  const json = await readJson(resp);
  if (resp.ok) {
    return json as Promise<T>;
  }

  throw new HttpError(resp.status, getStandardErrorMessage(json));
}

function getStandardErrorMessage(errObj: any) {
  if (errObj) {
    if ('message' in errObj) {
      return errObj.message;
    }
    return JSON.stringify(errObj);
  }
  return 'Something went wrong, but no response body was returned, or response was not JSON.  Please check the Network tab';
}

async function readJson(resp: Response) {
  try {
    return await resp.json();
  } catch (e) {
    return null;
  }
}
