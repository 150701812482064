import { BarChartOutlined } from '@ant-design/icons';
import { Col, Layout, message, Row, Tag } from 'antd';
import { groupBy, keyBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useApiFetch, useQuery } from '../hooks/useQueries';
import {
  createSfTimeEntry,
  deleteSfTimeEntry,
  updateSfTimeEntry,
} from '../lib/api/apiMutations';
import {
  AppState,
  BillingCode,
  DerivedTimeEntry,
  Employee,
  Id,
  TimeEntry,
  Timer,
} from '../lib/types';
import {
  getSecondsSince,
  roundToQuarterHours,
  validateTimeEntry,
} from '../lib/util';
import { AppContext } from './AppContext';
import { Dashboard } from './Dashboard';
import DateNavigator from './DateNavigator';
import Header from './Header';
import Paste from './Paste';
import PleaseLogin from './PleaseLogin';
import Spinner from './Spinner';
import TimeEntries from './TimeEntries';
import TotalsDay from './TotalsDay';
import TotalsDayUnsaved from './TotalsDayUnsaved';
import TotalsMonth from './TotalsMonth';
import TotalsQuarter from './TotalsQuarter';

/**
 * INTERFACES/TYPES
 */

// AppProps Shape - props that are passed from Root.tsx into App.tsx
export interface AppProps {
  persistedState: AppState;
  onPersistChanges: (state: AppState) => void;
}

const App = ({ persistedState, onPersistChanges }: AppProps) => {
  /**
   * STATE VALUES/VARIABLES
   */
  // persisted bearer token and from context
  const { bearerToken, setBearerToken } = React.useContext(AppContext);

  // persisted changed time entries for user, from local storage
  const [changedTimeEntries, setChangedTimeEntries] = React.useState(
    persistedState.changedTimeEntries || {}
  );

  // Current date navigated by DateNavigator
  const now = new Date();
  const [currentDate, setCurrentDate] = React.useState({
    year: now.getFullYear(),
    month: now.getMonth(),
    date: now.getDate(),
  });

  // Keep track of copy/paste time Entry
  const [timeEntryCopy, setTimeEntryCopy] =
    React.useState<Partial<TimeEntry>>(undefined);

  // Running Timer Id - Set onPlay, Unset onPause
  const [timer, setRunningTimer] = React.useState<Timer>(persistedState.timer);

  // Processing Time Entry Id - Set onSave, Unset when save completes
  const [processingIds, setProcessingIds] = React.useState<string[]>([]);

  const [showDashboard, setShowDashboard] = React.useState(false);

  /**
   * HOOK CALLS
   */

  // call useQuery hook to get updated employee data from API
  const {
    data: user,
    setData: setUser,
    error: userError,
    isLoading: isUserLoading,
    reset: resetUserFromApiQuery,
    refetch: refreshUser,
  } = useQuery<Employee>({ uri: `me` }, { initialValue: persistedState.user });

  // call useQuery hook to get updated time-entry data from API
  const {
    data: savedTimeEntries,
    setData: setSavedTimeEntries,
    error: timeEntriesError,
    isLoading: timeEntriesLoading,
    reset: resetTimeEntriesFromApiQuery,
    refetch: refreshTimeEntries,
  } = useQuery<Record<string, TimeEntry>>(
    { uri: `time-entries` },
    {
      initialValue: persistedState.savedTimeEntries || {},
      onSuccess: (results: TimeEntry[]) => keyBy(results, 'id'),
      skip: !user,
    }
  );

  // call useQuery hook to get updated client data TEST FORMAT
  const {
    data: clients,
    error: clientError,
    isLoading: clientsLoading,
    reset: resetClientsFromApiQuery,
    refetch: refreshClients,
  } = useQuery<Record<string, BillingCode[]>>( // TODO: refactor to instead just be a regular id -> billing code map
    { uri: `billing-codes` },
    {
      initialValue: persistedState.clients,
      skip: !user,
      onSuccess: (results: BillingCode[]) =>
        groupBy(results, (it) => it.clientId),
    }
  );

  // call useApiFetch hook to generate pre-validated apiFetch function to be used in CRUD operations
  const apiFetch = useApiFetch();

  /**
   * USE EFFECTS
   */
  // listen for changes to clientError and timeEntriesError values - if error exists, display error message to user
  useEffect(() => {
    // create unique id for error
    const errKey = uuidv4();
    [timeEntriesError, clientError, userError]
      .filter((err) => err)
      .forEach((err) =>
        message.error({
          content: err.displayError(),
          duration: 0,
          key: errKey,
          onClick() {
            // message will disappear on click
            message.destroy(errKey);
          },
        })
      );
  }, [timeEntriesError, clientError, userError]);

  // listen for changes to any of the react state variables that get saved in local storage - if they change, re-save all local storage variables with their current values.
  React.useEffect(() => {
    onPersistChanges({
      version: persistedState.version,
      savedTimeEntries,
      changedTimeEntries,
      clients,
      bearerToken,
      user,
      timer,
    });
  }, [
    persistedState.version,
    onPersistChanges,
    savedTimeEntries,
    changedTimeEntries,
    clients,
    bearerToken,
    user,
    timer,
  ]);

  /**
   * FUNCTIONS
   */
  // revert any changes from selected time entry to its previously saved state
  const revertTimeEntry = (id: Id) => () => {
    setChangedTimeEntries((existing) => {
      const clone = { ...existing };
      delete clone[id];
      return clone;
    });
  };

  // create a new time entry
  const newTimeEntry = () => {
    const id: string = uuidv4(); // random key.  get's replaced when saved
    startTimer(id);
    setChangedTimeEntries((existing) => {
      const newEntries: Record<string, Partial<TimeEntry>> = {
        ...existing,
        [id]: {
          billingCode: {
            active: true,
            id: '',
            clientId: '',
            clientName: '',
            fullCode: '',
            projectId: '',
            projectName: '',
          },
          createdAt: new Date().toISOString(),
          credited: false,
          duration: 0,
          id: id,
          note: '',
          billHours: 0,
          startedAt: currentDate, // CalendarDate Format - what SF stores date as
          taskType: 'billable', // populated by services dropdown
        },
      };
      return newEntries;
    });
    return id;
  };

  const getDerivedEntry = useCallback(
    (id: Id): DerivedTimeEntry => {
      const existing = savedTimeEntries[id];
      const changed = changedTimeEntries[id];

      return {
        ...(existing || {}),
        ...(changed || {}),
        unLoggedHours: changed?.duration
          ? roundToQuarterHours(changed?.duration) - (existing?.billHours ?? 0)
          : 0,
        isNew: existing == null,
        isDirty: changed != null || timer.runningTimerId === id,
      };
    },
    [savedTimeEntries, changedTimeEntries, timer]
  );

  // track and save any changes made to a time entry
  const editTimeEntry = (id: Id) => (field: keyof TimeEntry, value: any) => {
    setChangedTimeEntries((existing) => {
      const clone: any = existing[id] ? { ...existing[id] } : { id };
      clone[field] = value;
      // set to billable for all clients that don't need tasktypes, for consistency
      if (
        process.env.REACT_APP_NULL_TASKTYPE_CLIENTS.includes(
          clone.billingCode?.clientId
        )
      ) {
        clone.taskType = 'billable';
      }
      return {
        ...existing,
        [id]: clone,
      };
    });
  };

  // Handles response from API for create/update
  const handleSaveResponse = (saveMe: Partial<TimeEntry>, response: any) => {
    setChangedTimeEntries((existing) => {
      const clone = { ...existing };
      delete clone[saveMe.id];
      return clone;
    });

    setSavedTimeEntries((existing) => {
      return {
        ...existing,
        [response.id]: { ...response, active: true },
      };
    });
  };

  // create OR update the record in SF
  const saveTimeEntry = (timeEntry: DerivedTimeEntry) => async () => {
    setProcessingIds([timeEntry.id]);
    const effectiveTe = getDerivedEntry(timeEntry.id);
    if (timer.runningTimerId === timeEntry.id) {
      const newTime = stopTimer();
      //HACK to get updated state synchronously...
      // consider a refactor to useEffect
      effectiveTe.duration = newTime;
    }
    const existingRecord = savedTimeEntries[timeEntry.id];
    const validationResult = validateTimeEntry(timeEntry, timer);
    if (!validationResult.length) {
      try {
        const result = await (!existingRecord
          ? createSfTimeEntry(effectiveTe, apiFetch)
          : updateSfTimeEntry(effectiveTe, apiFetch));
        handleSaveResponse(effectiveTe, result);
        message.success(
          `Successfully ${!existingRecord ? 'Saved' : 'Updated'} Time Entry!`
        );
      } catch (e) {
        console.log('failed to save', e);
        message.error(`Failed to Save Record! Reason: ${String(e)}`);
      }
    } else {
      // this shouldn't happen... we should just prevent save being enabled if it's not valid...
      message.error(
        `Please add a: ${validationResult
          .map((result) => result.field)
          .join(', ')}`
      );
    }
    setProcessingIds([]);
  };

  // Button Action - onSaveAll
  const getOnSaveAll = (saveThese: TimeEntry[]) => async () => {
    const saveEmAll = saveThese.filter((te) => changedTimeEntries[te.id]);
    if (saveEmAll.length > 0) {
      setProcessingIds(saveEmAll.map((te) => te.id));

      const { valid, invalid } = saveEmAll.reduce(
        (acc, te) => {
          const effectiveTe = getDerivedEntry(te.id);

          if (timer.runningTimerId === te.id) {
            const newTime = stopTimer();
            effectiveTe.duration = newTime;
          }
          const validationResult = validateTimeEntry(te, timer);
          if (!validationResult.length) {
            acc.valid.push(effectiveTe);
          } else {
            acc.invalid.push(te);
          }
          return acc;
        },
        { valid: [], invalid: [] }
      );
      // if any time entries are valid -
      if (valid.length > 0) {
        valid.sort((a, b) =>
          Date.parse(a.createdAt) < Date.parse(b.createdAt) ? -1 : 1
        );
        // BUG: If any promise fails, this will throw!
        // We are likely running into a "ROW_LOCK" error when many time entries are saved at once.  Should consider creating as bulk endpoint
        const errors: Error[] = [];
        const responses = await Promise.all(
          valid.map((saveMe) => {
            const existingRecord = savedTimeEntries[saveMe.id];
            // save time Entry
            const req = !existingRecord
              ? createSfTimeEntry(saveMe, apiFetch)
              : updateSfTimeEntry(saveMe, apiFetch);
            return req
              .then((response) => handleSaveResponse(saveMe, response))
              .catch((e) => errors.push(e));
          })
        );

        message.success(
          `Successfully Saved ${responses.length} Time Entr${
            responses.length > 1 ? 'ies' : 'y'
          }!`
        );

        if (errors.length) {
          message.error(
            `${errors.length} Time Entr${
              errors.length > 1 ? 'ies Were' : 'y Was'
            } Failed to save`
          );
        }
      }
      if (invalid.length) {
        message.error(
          `${invalid.length} Time Entr${
            invalid.length > 1 ? 'ies Were' : 'y Was'
          } Not Valid To Be Saved`
        );
      }
    }
    // Disable Processing
    setProcessingIds([]);
  };

  // delete time entry in SF
  const deleteTimeEntry = (id: Id) => async () => {
    setProcessingIds([id]);
    // Stop timer if it is running
    if (timer.runningTimerId === id) {
      stopTimer();
    }
    // check if timeEntry exists in changedTimeEntries
    if (changedTimeEntries[id]) {
      setChangedTimeEntries((existing) => {
        const clone = { ...existing };
        delete clone[id];
        return clone;
      });
    }
    // check if timeEntry exists in savedTimeEntries
    if (savedTimeEntries[id]) {
      try {
        await deleteSfTimeEntry(id, apiFetch);
        setSavedTimeEntries((existing) => {
          const clone = { ...existing };
          delete clone[id];
          return clone;
        });
        message.success(`Successfully Deleted Time Entry!`);
      } catch (e) {
        message.error(e);
      }
    }
    setProcessingIds([]);
  };

  // Clear App Data
  const onClearAppData = () => {
    setRunningTimer({});
    setBearerToken(null);
    resetUserFromApiQuery();
    resetTimeEntriesFromApiQuery();
    resetClientsFromApiQuery();
  };

  // Refresh freshbooks data - stop timer from elapsing, reset time entries/clients, and use refresh function returned from useQuery hook to get new time-entry/client data
  const onRefresh = async () => {
    resetTimeEntriesFromApiQuery();
    resetClientsFromApiQuery();
    refreshTimeEntries();
    refreshClients();
  };

  const startTimer = (id: string) => {
    if (timer.runningTimerId) {
      stopTimer();
    }
    setRunningTimer({
      runningTimerId: id,
      currentTimerStart: new Date().getTime(),
    });
  };

  // Button Action - onPlay
  const stopTimer = () => {
    const { runningTimerId, currentTimerStart } = timer;
    const currentTe = changedTimeEntries[runningTimerId];

    const currentTime =
      currentTe?.duration !== undefined
        ? currentTe.duration
        : savedTimeEntries[runningTimerId].duration;

    console.log('stop', currentTe, currentTime);
    const newTime = currentTime + getSecondsSince(currentTimerStart);

    editTimeEntry(runningTimerId)('duration', newTime);
    setRunningTimer({});
    return newTime;
  };

  // Button Action - onPlay
  const getOnPlay = (id: string) => () => {
    startTimer(id);
  };

  // Button Action - onPause
  const getOnPause = () => () => {
    stopTimer();
  };

  // Button Action - onCopy
  const getOnCopy = (id: string) => () => {
    // Copy of time entry from either changedTimeEntries or savedTimeEntries
    setTimeEntryCopy(
      changedTimeEntries[id] ? changedTimeEntries[id] : savedTimeEntries[id]
    );
    message.success('Time Entry Copied To Clipboard!');
  };

  // Button Action - onPaste
  const onPaste = () => {
    const id: string = uuidv4(); // random key.  get's replaced when saved
    const blankTimeEntry: Partial<TimeEntry> = {
      ...timeEntryCopy,
      createdAt: new Date().toISOString(),
      id,
      duration: 0,
      startedAt: currentDate,
    };
    setChangedTimeEntries((existing) => {
      return { ...existing, [id]: blankTimeEntry };
    });

    return blankTimeEntry.id;
  };

  // Button Action - onClearPaste
  const onClearPaste = () => {
    setTimeEntryCopy(undefined);
  };

  const isCurrent = React.useCallback(
    (te: Partial<TimeEntry>) => {
      return moment(currentDate).isSame(moment(te.startedAt), 'day');
    },
    [currentDate]
  );

  const currentTimeEntries = React.useMemo(() => {
    const currentSaved = keyBy(
      Object.values(savedTimeEntries)
        .filter(isCurrent)
        .map((it) => getDerivedEntry(it.id)),
      'id'
    );
    const currentChanged = keyBy(
      Object.values(changedTimeEntries)
        .map((it) => getDerivedEntry(it.id))
        .filter(isCurrent),
      'id'
    );
    return { ...currentSaved, ...currentChanged };
  }, [savedTimeEntries, changedTimeEntries, getDerivedEntry, isCurrent]);

  // memorized logged quarter hours for current day, month, and quarter
  const {
    totalRoundedQuarterHoursCurrentDay,
    totalRoundedQuarterHoursCurrentMonth,
    totalRoundedQuarterHoursCurrentQuarter,
  } = React.useMemo(() => {
    return Object.values(savedTimeEntries).reduce(
      (acc, te) => {
        //... add time to totalSecondsCurrentQuarter and totalSecondCurrentMonth
        if (moment(te.startedAt).isSame(moment(currentDate), 'day')) {
          acc.totalRoundedQuarterHoursCurrentDay += roundToQuarterHours(
            te.duration
          );
        }
        if (moment(te.startedAt).isSame(moment(currentDate), 'month')) {
          acc.totalRoundedQuarterHoursCurrentMonth += roundToQuarterHours(
            te.duration
          );
        }
        if (
          moment(te.startedAt).utc().quarter() ===
            moment(currentDate).utc().quarter() &&
          moment(te.startedAt).isSame(moment(currentDate), 'year')
        ) {
          acc.totalRoundedQuarterHoursCurrentQuarter += roundToQuarterHours(
            te.duration
          );
        }
        return acc;
      },
      {
        totalRoundedQuarterHoursCurrentDay: 0,
        totalRoundedQuarterHoursCurrentMonth: 0,
        totalRoundedQuarterHoursCurrentQuarter: 0,
      }
    );
  }, [savedTimeEntries, currentDate]);

  const totalUnloggedRoundedQuarterHoursCurrentDay = React.useMemo(() => {
    return Object.values(currentTimeEntries).reduce((acc, te) => {
      acc += te.unLoggedHours;
      // console.log(te);
      return acc;
    }, 0);
  }, [currentTimeEntries]);

  // memorized dates of unsaved time entries
  const datesOfUnsavedTimeEntries = useMemo(() => {
    return Object.values(changedTimeEntries).reduce((acc, te) => {
      const effectiveTe = getDerivedEntry(te.id);
      acc.push(
        new Date(
          effectiveTe.startedAt.year,
          effectiveTe.startedAt.month,
          effectiveTe.startedAt.date
        )
      );
      return acc;
    }, []);
  }, [changedTimeEntries, getDerivedEntry]);

  // memorized ten most recently used billing codes (filter out billing codes which user is no longer associated with)
  const mostRecentBillingCodes: BillingCode[] = React.useMemo(() => {
    const res = new Map();
    Object.values(savedTimeEntries)
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .filter(
        (te) =>
          clients[te.billingCode.clientId] &&
          clients[te.billingCode.clientId]
            .map((billingCode) => billingCode.id)
            .includes(te.billingCode.id)
      )
      .forEach((te) => res.set(te.billingCode?.id, te.billingCode));
    const result = [];
    res.forEach((value, key) => {
      if (result.length === 10) {
        return;
      }
      result.push(value);
    });
    return result;
  }, [savedTimeEntries, clients]);

  return (
    <Layout>
      <Header
        user={user}
        onClearAppData={onClearAppData}
        onRefresh={onRefresh}
      />

      <Dashboard
        timeEntries={Object.values(savedTimeEntries)}
        isOpen={showDashboard}
        onClose={() => setShowDashboard(false)}
      />

      <Spinner
        isVisible={isUserLoading || timeEntriesLoading || clientsLoading}
      />
      {!bearerToken || !savedTimeEntries || !clients ? (
        <PleaseLogin />
      ) : (
        <>
          <Row style={{ padding: 10 }}>
            <Col span={6} style={{ textAlign: 'left' }}>
              <TotalsQuarter
                currentDate={currentDate}
                hours={totalRoundedQuarterHoursCurrentQuarter}
              />
              <br />
              <TotalsMonth
                currentDate={currentDate}
                hours={totalRoundedQuarterHoursCurrentMonth}
              />
              <br />
              <Tag
                style={{ marginTop: 5, cursor: 'pointer' }}
                color={'green'}
                onClick={() => setShowDashboard(true)}
              >
                <BarChartOutlined /> Open Dashboard
              </Tag>
            </Col>
            <Col
              span={12}
              style={{
                display: 'flex',
                textAlign: 'center',
                paddingTop: 5,
                whiteSpace: 'nowrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DateNavigator
                currentDate={
                  new Date(
                    currentDate.year,
                    currentDate.month,
                    currentDate.date
                  )
                }
                onSetCurrentDate={setCurrentDate}
                unsavedDates={datesOfUnsavedTimeEntries}
              />
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
              <TotalsDay
                currentDate={currentDate}
                hours={totalRoundedQuarterHoursCurrentDay}
              />
              <br />
              <TotalsDayUnsaved
                currentDate={currentDate}
                hours={totalUnloggedRoundedQuarterHoursCurrentDay}
              />
            </Col>
          </Row>
          <TimeEntries
            canAddNewRecord={
              moment(currentDate).utc().quarter() === moment().quarter() &&
              currentDate.year === new Date().getFullYear()
            }
            clients={clients}
            currentTimeEntries={currentTimeEntries}
            getOnChange={editTimeEntry}
            getOnCopy={getOnCopy}
            getOnDelete={deleteTimeEntry}
            getOnPause={getOnPause}
            getOnPlay={getOnPlay}
            getOnSaveAll={getOnSaveAll}
            getOnRevert={revertTimeEntry}
            mostRecentBillingCodes={mostRecentBillingCodes}
            onCreate={newTimeEntry}
            onSaveTimeEntry={saveTimeEntry}
            processingIds={processingIds}
            timer={timer}
          />
        </>
      )}
      <Paste
        isDisabled={
          currentDate.year !== new Date().getFullYear() ||
          moment(currentDate).utc().quarter() !== moment().quarter()
        }
        isVisible={timeEntryCopy !== undefined}
        onClear={onClearPaste}
        onPaste={onPaste}
      />
    </Layout>
  );
};

export default App;
