import { Input } from 'antd';

const TimeCardNotes = (props: {
  isDisabled: boolean;
  note: string;
  onChange: (v: string) => void;
}) => {
  return (
    <Input.TextArea
      disabled={props.isDisabled}
      rows={3}
      value={props.note}
      style={{ marginTop: 10, height: '93%' }}
      placeholder="Notes....."
      onChange={(e) => props.onChange(e.target.value)}
    />
  );
};

export default TimeCardNotes;
