import React from 'react';
import { Card } from 'antd';

const PleaseLogin = () => {
  return (
    <Card style={{ textAlign: 'center' }}>
      Before you are able to add any time entries, you'll need to login to fetch
      the projects associated to your Google account in Salesforce.
    </Card>
  );
};

export default PleaseLogin;
