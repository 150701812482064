import React from 'react';

// ContextData Shape - props that AppContext exposes to app via useContext hook
export interface ContextData {
  bearerToken?: string | null;
  setBearerToken?: React.Dispatch<React.SetStateAction<string>>;
}

// AppContextProviderProps Shape - props that are passed into AppContext.tsx from Root.tsx
export interface AppContextProviderProps {
  children: React.ReactNode;
  localStorageBearerToken: string | null;
}

export const AppContext = React.createContext<ContextData>({});

export const AppContextProvider = (props: AppContextProviderProps) => {
  // context state for bearer token, defaults to locally stored value or null
  const [bearerToken, setBearerToken] = React.useState(
    props.localStorageBearerToken
  );

  return (
    <AppContext.Provider value={{ bearerToken, setBearerToken }}>
      {props.children}
    </AppContext.Provider>
  );
};
