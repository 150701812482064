import { ResponsiveCalendar } from '@nivo/calendar';
import moment from 'moment';
import { TimeEntry } from '../../lib/types';

interface Props {
  timeEntries: TimeEntry[];
}
export const Calendar = ({ timeEntries }: Props) => {
  const hoursByDay = timeEntries.reduce<Record<string, number>>(
    (acc, timeEntry) => {
      const date = moment(timeEntry.startedAt).format('YYYY-MM-DD');
      acc[date] = (acc[date] || 0) + timeEntry.billHours;
      return acc;
    },
    {}
  );
  const hoursByDayList = Object.keys(hoursByDay)
    .map((date) => ({ day: date, value: hoursByDay[date] }))
    .sort((a, b) => Date.parse(a.day) - Date.parse(b.day));
  return (
    <div style={{ height: 300 }}>
      <ResponsiveCalendar
        data={hoursByDayList}
        from={hoursByDayList[0]?.day}
        to={hoursByDayList[hoursByDayList.length - 1]?.day}
        emptyColor="#eeeeee"
        colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
        margin={{ top: 0, right: 40, bottom: 20, left: 40 }}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'row',
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: 'right-to-left',
          },
        ]}
      />
    </div>
  );
};
