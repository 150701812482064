import { Switch } from 'antd';
import { DerivedTimeEntry } from '../../lib/types';

const BillableSwitch = (props: {
  timeEntry: DerivedTimeEntry;
  onChange: (field: keyof DerivedTimeEntry, value: any) => void;
}) => {
  // onChange
  const onChange = (value: string): void => {
    props.onChange('taskType', value);
  };

  return (
    <Switch
      style={{ marginTop: 10 }}
      checked={props.timeEntry.taskType !== 'credit'}
      onChange={(checked) => onChange(checked ? 'billable' : 'credit')}
      checkedChildren="Billable"
      unCheckedChildren="Credit"
    />
  );
};

export default BillableSwitch;
