import React from 'react';
import { Button, Tooltip } from 'antd';
import { CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { colors } from '../lib/colors';

const Paste = (props: {
  isVisible: boolean;
  isDisabled: boolean;
  onPaste: () => void;
  onClear: () => void;
}) => {
  const [closeVisible, setCloseVisible] = React.useState(false);
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 13,
        right: 13,
        ...(props.isVisible ? {} : { display: 'none' }),
      }}
      onMouseEnter={() => setCloseVisible(true)}
      onMouseLeave={() => setCloseVisible(false)}
    >
      <CloseOutlined
        style={{
          border: '1px solid #AAA',
          color: '#555',
          backgroundColor: '#FFF',
          borderRadius: 2,
          position: 'absolute',
          top: -7,
          right: -7,
          zIndex: 1,
          transitionProperty: 'opacity',
          transitionDuration: '300ms',
          transitionTimingFunction: 'linear',
          ...(closeVisible ? { opacity: 1 } : { opacity: 0 }),
        }}
        onClick={props.onClear}
      />
      <Tooltip placement={'top'} title={'Paste'}>
        <Button
          type="primary"
          disabled={props.isDisabled}
          style={{
            width: 60,
            height: 60,
            ...(!props.isDisabled ? colors.purple : {}),
          }}
          onClick={props.onPaste}
          icon={<CopyOutlined style={{ fontSize: 40 }} />}
        />
      </Tooltip>
    </div>
  );
};

export default Paste;
