import React from 'react';
import { Tooltip } from 'antd';
import { CalendarDate } from '../lib/types';

const TotalsQuarter = (props: { hours: number; currentDate: CalendarDate }) => {
  const quarters = [1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 4];
  const quarter = quarters[props.currentDate.month];
  return (
    <Tooltip placement={'right'} title={'Hours logged for the quarter'}>
      {`Q${quarter}: ${props.hours.toFixed(2)}`}
    </Tooltip>
  );
};

export default TotalsQuarter;
