import {
  getPersistedState,
  initState,
  updatePersistedState,
} from './localStorage';

/** WARNING: Updates always run using the previous version of the code!
 * TODO: figure out a better way to handle if state should be reset.  Maybe pull the version from the API?
 */
export const update = (registration: ServiceWorkerRegistration) => {
  const updateConfirmed = window.confirm(
    `New version available!  Click ok to update...
      WARNING: you will lose any unsaved changes! You may postpone the update by clicking cancel, but make sure you save all your changes before reloading the tab`
  );
  if (updateConfirmed) {
    resetPersistedState();
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
    window.location.reload();
  }
};

// for now, just clear the 'readonly' state so it will refresh.
// This could cause issues if the data model around changedTimeEntries has changed
export const resetPersistedState = () => {
  const { bearerToken, changedTimeEntries } = getPersistedState();
  updatePersistedState({ ...initState, bearerToken, changedTimeEntries });
};
