import { Drawer, Tabs } from 'antd';
import { TimeEntry } from '../../lib/types';
import { AggregateBarChart } from './AggregateBar';
import { Calendar } from './Calendar';

interface DashboardProps {
  timeEntries: TimeEntry[];
  isOpen: boolean;
  onClose: () => void;
}

export const Dashboard = ({ timeEntries, isOpen, onClose }: DashboardProps) => {
  if (!isOpen) return null;
  return (
    <Drawer
      title="Dashboard"
      placement="top"
      closable={true}
      onClose={onClose}
      open={isOpen}
      height="100%"
    >
      <Tabs>
        <Tabs.TabPane tab="Aggregate Hours" key="bar">
          <AggregateBarChart timeEntries={timeEntries} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Calendar" key="calendar">
          <Calendar timeEntries={timeEntries} />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
};
