import React from 'react';
import { Tooltip } from 'antd';
import { CalendarDate } from '../lib/types';

const TotalsDayUnsaved = (props: {
  hours: number;
  currentDate: CalendarDate;
}) => {
  return (
    <Tooltip placement={'left'} title={'Hours NOT logged today'}>
      {`${props.hours.toFixed(2)} :Unsaved`}
    </Tooltip>
  );
};

export default TotalsDayUnsaved;
