import { Button, message } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from './AppContext';
import { GoogleLogin } from '@react-oauth/google';

const Login = (props: { onClearAppData: () => void }): JSX.Element => {
  // context values for bearerToken/setBearerToken/setUser
  const { bearerToken, setBearerToken } = useContext(AppContext);

  if (bearerToken !== null) {
    return (
      <div>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            props.onClearAppData();
          }}
        >
          Logout
        </Button>
      </div>
    );
  } else {
    return (
      <div>
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            console.log(credentialResponse.credential);
            setBearerToken(credentialResponse.credential);
          }}
          onError={() => {
            message.error('Login Failed');
          }}
        />
      </div>
    );
  }
};

export default Login;
