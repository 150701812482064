import { BarLayer, ResponsiveBar } from '@nivo/bar';
import { Form, Segmented, Space, Tag } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { TimeEntry } from '../../lib/types';

interface Props {
  timeEntries: TimeEntry[];
}
export const AggregateBarChart = ({ timeEntries }: Props) => {
  const [groupKey, setGroupKey] = useState<'fullCode' | 'clientName'>(
    'fullCode'
  );
  const [period, setPeriod] = useState<'month' | 'week' | 'quarter'>('month');

  const [showBillable, setShowBillable] = useState(true);
  const [showCredit, setShowCredit] = useState(true);
  const [showNonBillable, setShowNonBillable] = useState(true);

  const allGroups = new Set<string>();
  const billingCodeHoursByMonth = timeEntries.reduce<Record<string, any>>(
    (acc, timeEntry) => {
      allGroups.add(timeEntry.billingCode[groupKey]);

      const momentDate = moment(timeEntry.startedAt);

      const isNonBillable =
        process.env.REACT_APP_NULL_TASKTYPE_CLIENTS.includes(
          timeEntry.billingCode?.clientId
        );

      const taskType = isNonBillable
        ? 'non-billable'
        : timeEntry.credited
        ? 'credit'
        : 'billable';

      if (
        (!showBillable && taskType === 'billable') ||
        (!showCredit && taskType === 'credit') ||
        (!showNonBillable && taskType === 'non-billable')
      ) {
        return acc;
      }

      const date = (() => {
        switch (period) {
          case 'month':
            return momentDate.format('YYYY-MM');
          case 'quarter':
            return momentDate.format('YYYY-Q');
          case 'week':
            return momentDate.format('YY-W');
        }
      })();

      const groupHours =
        (acc[date]?.[timeEntry.billingCode[groupKey]] ?? 0) +
        timeEntry.billHours;

      acc[date] = {
        ...(acc[date] ?? {}),
        [timeEntry.billingCode[groupKey]]: groupHours,
      };
      return acc;
    },
    {}
  );
  const billingCodeHoursByMonthList = Object.keys(billingCodeHoursByMonth)
    .slice(-35) //limit of how many weeks we can display without the labels become unreadable
    .map((period) => ({
      period,
      ...billingCodeHoursByMonth[period],
    }));
  return (
    <>
      <Form layout="inline">
        <Form.Item label="Group By">
          <Segmented
            value={groupKey}
            onChange={setGroupKey as any}
            options={[
              { label: 'Billing Code', value: 'fullCode' },
              { label: 'Client', value: 'clientName' },
            ]}
          />
        </Form.Item>
        <Form.Item label="Period">
          <Segmented
            value={period}
            onChange={setPeriod as any}
            options={[
              { label: 'Quarter', value: 'quarter' },
              { label: 'Month', value: 'month' },
              { label: 'Week', value: 'week' },
            ]}
          />
        </Form.Item>
        <Form.Item label="Entry Type">
          <Space size={[0, 8]} wrap>
            <Tag.CheckableTag
              checked={showBillable}
              onChange={(checked) => setShowBillable(checked)}
            >
              Billable
            </Tag.CheckableTag>
            <Tag.CheckableTag
              checked={showCredit}
              onChange={(checked) => setShowCredit(checked)}
            >
              Credit
            </Tag.CheckableTag>
            <Tag.CheckableTag
              checked={showNonBillable}
              onChange={(checked) => setShowNonBillable(checked)}
            >
              Non-Billable
            </Tag.CheckableTag>
          </Space>
        </Form.Item>
      </Form>
      <div style={{ height: 400 }}>
        <ResponsiveBar
          data={billingCodeHoursByMonthList}
          keys={Array.from(allGroups)}
          indexBy="period"
          margin={{ top: 25, right: 170, bottom: 60, left: 60 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'nivo' }}
          layers={[
            'grid',
            'axes',
            'markers',
            'bars',
            'legends',
            'annotations',
            BarTotalsLayer,
          ]}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: `Period: ${period}`,
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Hours',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
        />
      </div>
    </>
  );
};

const BarTotalsLayer: BarLayer<{ value: number }> = ({
  bars,
  xScale,
  yScale,
}) => {
  const labelOffset = 10;
  const labelFontSize = 12;
  if (bars.length === 0) return null;
  // compute totals for each index/bar

  const totals = bars.reduce<Record<number, number>>((acc, bar) => {
    const indexValue = bar.data.indexValue;
    if (!(indexValue in acc)) {
      acc[indexValue] = 0;
    }
    if (!bar.data.hidden) {
      acc[indexValue] += bar.data.value;
    }
    return acc;
  }, {});

  const bandwidth = bars[0]?.width;
  // place text elements above the bars
  const labels = (Object.keys(totals) as any as number[]).map((indexValue) => {
    const x = xScale(indexValue) + bandwidth / 2;
    const y = yScale(totals[indexValue]) - labelOffset;
    return (
      <text
        key={'total.' + indexValue}
        x={x}
        y={y}
        textAnchor={'middle'}
        fontWeight={'bold'}
        fontSize={labelFontSize}
      >
        {totals[indexValue]}
      </text>
    );
  });
  return <>{labels}</>;
};
