import React from 'react';
import { Spin } from 'antd';

const Spinner = (props: { isVisible: boolean }) => {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 1000,
        width: '100%',
        height: '100%',
        ...(props.isVisible ? {} : { display: 'none' }),
      }}
    >
      <Spin
        size="large"
        style={{
          position: 'fixed',
          backgroundColor: '#FFF',
          opacity: '80%',
          width: '100%',
          height: '100%',
          paddingTop: 'calc(50vh - 25px)',
        }}
      />
    </div>
  );
};

export default Spinner;
