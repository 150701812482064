import React from 'react';
import {
  BillingCode,
  DerivedTimeEntry,
  Id,
  TimeEntry,
  Timer,
} from '../lib/types';
import TimeCard from './TimeCard';
import {
  CloudUploadOutlined,
  FieldTimeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Card, Button, Empty, Tooltip } from 'antd';
import { colors } from '../lib/colors';
import { roundToQuarterHours, validateTimeEntry } from '../lib/util';

type TimeEntryListProps = {
  canAddNewRecord: boolean;
  clients: Record<string, BillingCode[]>;
  currentTimeEntries: Record<string, DerivedTimeEntry>;
  getOnChange: (id: Id) => (field: keyof TimeEntry, value: any) => void;
  getOnCopy: (id: string) => () => void;
  getOnDelete: (id: Id) => () => void;
  getOnPause: () => () => void;
  getOnPlay: (id: string) => () => void;
  getOnRevert: (id: Id) => () => void;
  getOnSaveAll: (timeEntries: Partial<TimeEntry>[]) => () => void;
  mostRecentBillingCodes: BillingCode[];
  onSaveTimeEntry: (timeEntry: DerivedTimeEntry) => () => void;
  onCreate: () => string;
  processingIds: string[];
  timer: Timer;
};

const TimeEntries = ({
  canAddNewRecord,
  clients,
  currentTimeEntries,
  getOnChange,
  getOnCopy,
  getOnDelete,
  getOnPause,
  getOnPlay,
  getOnRevert,
  getOnSaveAll,
  mostRecentBillingCodes,
  onCreate,
  onSaveTimeEntry,
  processingIds,
  timer,
}: TimeEntryListProps) => {
  const saveAllTimeEntries: Partial<TimeEntry>[] = [];

  const entriesInOrder = Object.values(currentTimeEntries).sort(
    (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
  );

  // disable save all button if all time entries have been logged OR if any time entries are invalid
  const saveAllIsDisabled =
    entriesInOrder.filter(
      (it) => it.isDirty && !validateTimeEntry(it, timer).length
    ).length < 1;

  const timeCardJSX = entriesInOrder.map((it) => {
    const roundedDuration = roundToQuarterHours(it.duration) * 3600;
    //TODO: let the server handle this!
    const saveableTimeEntry = { ...it, duration: roundedDuration };
    saveAllTimeEntries.push(saveableTimeEntry);
    return (
      <TimeCard
        mostRecentBillingCodes={mostRecentBillingCodes}
        clients={clients}
        isDirty={it.isDirty}
        isDisabled={!it.billingCode?.active}
        isProcessing={processingIds.includes(it.id)}
        isRunning={timer.runningTimerId === it.id}
        isSaved={!it.isNew}
        key={it.id}
        onChange={getOnChange(it.id)}
        onCopy={getOnCopy(it.id)}
        onDelete={getOnDelete(it.id)}
        onPause={getOnPause()}
        onPlay={getOnPlay(it.id)}
        onRevert={getOnRevert(it.id)}
        onSave={onSaveTimeEntry(saveableTimeEntry)}
        timeEntry={it}
        timer={timer}
        timerStart={timer.currentTimerStart}
      />
    );
  });
  // Save All
  const saveAll = getOnSaveAll(saveAllTimeEntries);

  return (
    <Card
      size="small"
      title={
        <span>
          <FieldTimeOutlined />
          &nbsp; Timers
        </span>
      }
      extra={
        <>
          <Tooltip placement={'top'} title={'New Timer'}>
            <Button
              type="primary"
              disabled={!canAddNewRecord}
              onClick={onCreate}
              icon={<PlusOutlined />}
              style={{ float: 'right', margin: 0 }}
            />
          </Tooltip>
          <Tooltip placement={'top'} title={'Save All'}>
            <Button
              id="save-all"
              type="primary"
              onClick={saveAll}
              icon={<CloudUploadOutlined />}
              style={{
                float: 'right',
                marginRight: 3,
                ...(saveAllIsDisabled ? {} : colors.blue),
              }}
              disabled={saveAllIsDisabled}
            />
          </Tooltip>
        </>
      }
      style={{ minWidth: 600, maxWidth: 1800 }}
    >
      {entriesInOrder.length ? timeCardJSX : <Empty />}
    </Card>
  );
};

export default TimeEntries;
