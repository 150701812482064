import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  RollbackOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import MoreButton from './MoreButton';
import { colors } from '../../lib/colors';
import useTimeout from '../../hooks/useTimeout';
import { ValidationResult } from '../../lib/types';

type TimeCardActionsProps = {
  incompleteFields: ValidationResult[];
  onlyExistsLocally: boolean;
  isDisabled: boolean;
  isRunning: boolean;
  isDirty: boolean;
  onPlay: () => void;
  onPause: () => void;
  onSave: () => void;
  onCopy: () => void;
  onRevert: () => void;
  onDelete: () => void;
};

const TimeCardActions = ({
  incompleteFields,
  onlyExistsLocally,
  isDisabled,
  isRunning,
  isDirty,
  onPlay,
  onPause,
  onSave,
  onCopy,
  onRevert,
  onDelete,
}: TimeCardActionsProps) => {
  const [isVisible, setVisible] = React.useState(false);
  const [isHovered, setHovered] = React.useState(false);

  useTimeout(() => setVisible(false), isVisible && !isHovered ? 1500 : null);
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        height: '100%',
        paddingRight: '25px',
      }}
      onMouseLeave={() => setHovered(false)}
      onMouseEnter={() => setHovered(true)}
    >
      <PlayButton
        isVisible={!isRunning}
        isDisabled={isDisabled}
        onPlay={onPlay}
      />
      <PauseButton isVisible={isRunning} onPause={onPause} />

      <SaveButton
        isDisabled={
          !isDirty ||
          !navigator.onLine ||
          isDisabled ||
          incompleteFields.length !== 0
        }
        incompleteFields={incompleteFields}
        onSave={onSave}
      />
      <MoreButton
        show={() => setVisible(true)}
        hide={() => setVisible(false)}
        isVisible={isVisible}
        content={
          <>
            <CopyButton onCopy={onCopy} />
            <RevertButton
              isDisabled={onlyExistsLocally || !isDirty || isRunning}
              onRevert={onRevert}
            />
            <DeleteButton
              isDirty={isDirty}
              isDisabled={!navigator.onLine || isDisabled}
              onlyExistsLocally={onlyExistsLocally}
              onDelete={onDelete}
            />
          </>
        }
      />
    </div>
  );
};

const PlayButton = (props: {
  isVisible: boolean;
  isDisabled: boolean;
  onPlay: () => void;
}) => {
  const { isVisible, isDisabled, onPlay } = props;
  return (
    <Tooltip placement={'top'} title={'Start Timer'}>
      <Button
        type="primary"
        size="small"
        onClick={onPlay}
        disabled={isDisabled}
        icon={<PlayCircleOutlined />}
        style={{
          verticalAlign: 'baseline',
          margin: 1,
          boxShadow: 'none',
          ...(isVisible ? {} : { display: 'none' }),
          ...(!isDisabled ? colors.green : {}),
        }}
        key="pausePlayBtn"
      />
    </Tooltip>
  );
};

const PauseButton = (props: { isVisible: boolean; onPause: () => void }) => {
  const { isVisible, onPause } = props;
  return (
    <Tooltip placement={'top'} title={'Stop Timer'}>
      <Button
        type="primary"
        size="small"
        onClick={onPause}
        icon={<PauseOutlined />}
        ghost={true}
        style={{
          verticalAlign: 'baseline',
          margin: 1,
          boxShadow: 'none',
          ...colors.gold,
          ...(isVisible ? {} : { display: 'none' }),
        }}
        key="pausePlayBtn"
      />
    </Tooltip>
  );
};

const SaveButton = (props: {
  incompleteFields: ValidationResult[];
  isDisabled: boolean;
  onSave: () => void;
}) => {
  const { incompleteFields, isDisabled, onSave } = props;
  return (
    <Tooltip
      placement={'top'}
      title={
        incompleteFields.length
          ? `Please Complete The Following Fields: ${incompleteFields
              .map((result) => result.field)
              .join(', ')}`
          : 'Save'
      }
    >
      <Button
        type="primary"
        size="small"
        disabled={isDisabled}
        onClick={onSave}
        icon={<CloudUploadOutlined />}
        style={{
          verticalAlign: 'baseline',
          margin: 1,
          boxShadow: 'none',
          ...(!isDisabled ? colors.blue : {}),
        }}
        key="saveBtn"
      />
    </Tooltip>
  );
};

const CopyButton = (props: { onCopy: () => void }) => {
  const { onCopy } = props;
  return (
    <Tooltip placement={'top'} title={'Copy to clipboard'}>
      <Button
        type="primary"
        size="small"
        key="copyBtn"
        onClick={onCopy}
        style={{
          verticalAlign: 'baseline',
          margin: 1,
          boxShadow: 'none',
          ...colors.purple,
        }}
        icon={<CopyOutlined />}
      />
    </Tooltip>
  );
};

const RevertButton = (props: { isDisabled: boolean; onRevert: () => void }) => {
  const { isDisabled, onRevert } = props;
  return (
    <Popconfirm
      placement="left"
      title="Reverting this timer to the last saved cannot be undone. Are you sure you want to revert?"
      key="revertPopConfirm"
      okText="Yes"
      cancelText="No"
      disabled={isDisabled}
      onConfirm={onRevert}
    >
      <Tooltip placement={'top'} title={'Revert to last Save'}>
        <Button
          type="primary"
          size="small"
          key="revertBtn"
          disabled={isDisabled}
          style={{
            verticalAlign: 'baseline',
            margin: 1,
            boxShadow: 'none',
            ...(!isDisabled ? colors.cyan : {}),
          }}
          icon={<RollbackOutlined />}
        />
      </Tooltip>
    </Popconfirm>
  );
};

const DeleteButton = (props: {
  isDirty: boolean;
  isDisabled: boolean;
  onlyExistsLocally: boolean;
  onDelete: () => void;
}) => {
  const { isDirty, isDisabled, onlyExistsLocally, onDelete } = props;
  const hasUnsavedChangesAnd = isDirty ? 'has unsaved changes and ' : '';
  const title = onlyExistsLocally
    ? `This timer ${hasUnsavedChangesAnd}has never been saved in Salesforce. Are you sure you want to delete it?`
    : `This timer ${hasUnsavedChangesAnd}will be permanately deleted in Salesforce. Are you sure you want to delete it?`;

  return (
    <Popconfirm
      placement="left"
      title={title}
      key="deletePopConfirm"
      okText="Yes"
      cancelText="No"
      onConfirm={onDelete}
      disabled={isDisabled}
    >
      <Tooltip placement={'top'} title={'Delete'}>
        <Button
          type="primary"
          size="small"
          key="deleteBtn"
          disabled={isDisabled}
          style={{
            verticalAlign: 'baseline',
            margin: 1,
            boxShadow: 'none',
            ...(!isDisabled ? colors.red : {}),
          }}
          icon={<DeleteOutlined />}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default TimeCardActions;
