import { AppState } from './types';

export const initState: AppState = {
  version: 1, // increment this when you make a breaking change to the state
  savedTimeEntries: {},
  changedTimeEntries: {},
  clients: {},
  bearerToken: null,
  user: null,
  timer: {},
};

export const LOCAL_STORAGE_KEY = 'persistedState';
export const getPersistedState = () => {
  const localStorageState =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {};

  // handle state created before we versioned it
  if (localStorageState.version) {
    localStorageState.version = 0;
  }

  // set based off localStorage for 'persisted state'
  return { ...initState, ...localStorageState } as AppState;
};

export const updatePersistedState = (state: AppState) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ ...state }));
};
